$dta_primary: #ed2f3c;
$dta_background: #e7ecf7;
$dta_header: #929497;

$primary: $dta_primary;
$secondary: $dta_header;
$success: #00b74a;
$info: #39c0ed;
$warning: #ffa900;
$danger: #f93154;
