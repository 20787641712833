.login-container {
  padding: 0;
  header {
    background: $dta_primary;
    padding: 1rem 2rem;

    img {
      max-height: 4rem;
    }
  }
}
