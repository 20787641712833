@import "./vars";
@import "https://use.fontawesome.com/releases/v5.15.1/css/all.css";
@import "./mdb";
@import "../fonts/style";

body,
html {
  font-family: "Museo", sans-serif;
  font-weight: normal;
  font-style: normal;
  background: $dta_background;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

#root {
  width: 100%;
  height: 100%;
}

.main-container {
  padding: 0;
  header {
    background: $dta_primary;
    color: $white;
    padding: 1rem 2rem;

    img {
      max-height: 4rem;
    }
  }
}

.dropdown-toggle.nav-link {
  cursor: pointer;
}

@import "./components/all";
@import "./login.page";

.navbar-nav {
  align-items: center;
}
