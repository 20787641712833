@import "./spinner";

.reports-table {
  .reports-table-file {
    .btn {
      white-space: normal;
      word-wrap: break-word;
      -webkit-appearance: normal;
      display: block;
      overflow-wrap: anywhere;
    }
  }
}
