.spinner {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 5rem;
  div {
    position: absolute;
    top: 2.063rem;
    width: 0.813rem;
    height: 0.813rem;
    border-radius: 50%;
    background: $dta_primary;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 0.5rem;
      animation: spinner-animation1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 0.5rem;
      animation: spinner-animation2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 2rem;
      animation: spinner-animation2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 3.5rem;
      animation: spinner-animation3 0.6s infinite;
    }
  }
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba($dta_background, 0.5);
}

.spinner-content {
  display: flex;
  width: 100%;
  margin-top: 2rem;

  align-items: center;
  justify-content: center;
}

@keyframes spinner-animation1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner-animation3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner-animation2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(1.5rem, 0);
  }
}
